<template>
  <div class="login-page">
    <v-container>
      <v-row justify="center" class="mt-16">
        <v-col cols="12" lg="6">
          <v-card flat class="rounded-lg mb-5" :loading="isLoading" :disabled="isLoading">
            <div v-if="!success">

              <form @submit.prevent="sendCode" v-if="!otp">
                <v-card-text class="pa-6 pa-lg-12">
                  <div class="text-center">
                    <v-icon size="100" color="red">mdi-alert-outline</v-icon>
                  </div>
                  <h2 class="text-center mb-lg-8 my-4">Supprimer mon compte GIFTY !</h2>
                  <p class="text-center">
                    Veuillez confirmer la suppression de votre compte. Cette action est irréversible.
                  </p>
                  <v-text-field
                      outlined
                      dense
                      prepend-inner-icon="mdi-phone"
                      v-model="form.phone"
                      label="Numéro téléphone de votre compte *"
                  ></v-text-field>
                  <v-textarea
                      outlined
                      dense
                      rows="3"
                      v-model="form.message"
                      label="Message"
                  ></v-textarea>
                  <div id="recaptcha-container" :data-sitekey="getSiteKey"></div>
                  <v-btn type="submit"
                         depressed
                         large
                         block
                         color="primary"
                         class="rounded-lg">
                    Confirmer
                  </v-btn>
                </v-card-text>
              </form>

              <form @submit.prevent="sendRequest" v-if="otp">
                <v-card-text class="pa-6 pa-lg-12">

                  <div class="text-center">
                    <h3 class="mb-3">
                      Confirmer votre numéro de téléphone
                    </h3>

                    <p>
                      Veuillez saisir le code de 6 chiffres
                    </p>
                  </div>

                  <p class="d-flex justify-center">
                    <OtpInput
                        ref="otpInput"
                        input-classes="otp-input"
                        separator=""
                        :num-inputs="6"
                        :should-auto-focus="true"
                        :is-input-num="true"
                        @on-change="handleOnChange"
                        @on-complete="handleOnComplete"
                    />
                  </p>
                  <v-alert color="red" dismissible dense v-if="codeError">
                    Code Incorrect !
                  </v-alert>
                  <div class="text-center">
                    <v-progress-circular color="primary" indeterminate v-if="checkLoading"/>
                  </div>
                </v-card-text>
              </form>

            </div>
            <div v-else>
              Votre demande a été envoyée avec succès. Votre compte sera supprimé définitivement après 24h.
            </div>
          </v-card>
          <p class="text-center white--text mt-10">
            © Copyright {{ new Date().getFullYear() }}
            <strong class="text-primary">Gifty</strong> Tous droits réservés.
          </p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import OtpInput from "@bachdgvn/vue-otp-input";
import {initializeApp} from "firebase/app";
import {getAuth, RecaptchaVerifier, GoogleAuthProvider, signInWithPhoneNumber} from "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_apiKey,
  authDomain: process.env.VUE_APP_authDomain,
  databaseURL: process.env.VUE_APP_databaseURL,
  projectId: process.env.VUE_APP_projectId,
  storageBucket: process.env.VUE_APP_storageBucket,
  messagingSenderId: process.env.VUE_APP_messagingSenderId,
  appId: process.env.VUE_APP_appId,
  measurementId: process.env.VUE_APP_measurementId,
};

let app;
let auth;

export default {
  components: {
    OtpInput,
  },
  data() {
    return {
      otp: false,
      isLoading: false,
      otpError: null,
      form: {
        phone: null,
        message: null,
        token: null,
      },
      formErrors: {},
      checkLoading: false,
      codeError: false,
      phoneConfirmed: false,
      success: false,
    };
  },
  methods: {
    sendCode() {
      this.initializeCaptcha();
    },
    getSiteKey() {
      return process.env.VUE_APP_recaptcha_sitekey;
    },
    initializeCaptcha() {
      if (!app) {
        app = initializeApp(firebaseConfig);
        auth = getAuth(app);
      }

      this.isLoading = true;
      this.otpError = null;

      window.recaptchaVerifier = new RecaptchaVerifier(
          "recaptcha-container",
          {
            size: "invisible",
          },
          auth
      );

      const appVerifier = window.recaptchaVerifier;
      signInWithPhoneNumber(auth, this.form.phone, appVerifier)
          .then((confirmationResult) => {
            window.confirmationResult = confirmationResult;
            this.isLoading = false;
            this.otp = true;
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.code === "auth/too-many-requests") {
              this.otpError = "Trop de tentatives, veuillez réessayer ultérieurement";
            } else {
              this.otpError = "Erreur de vérification. Veuillez réessayer.";
            }
          });
    },
    checkCode(code) {
      this.checkLoading = true;
      window.confirmationResult
          .confirm(code)
          .then((result) => {
            this.checkLoading = false;
            this.codeError = false;
            this.phoneConfirmed = true;
            this.form.token = result.user.accessToken;
          })
          .catch(() => {
            this.checkLoading = false;
            this.codeError = true;
          });
    },
    handleOnComplete(value) {
      this.checkCode(value);
    },
    handleOnChange(value) {
      // Optionally handle change
    },
    sendRequest() {
      this.isLoading = true;
      this.formErrors = {};
      axios.post(process.env.VUE_APP_BASE_URL + 'v1/delete-account-request', {
            token: this.form.token,
            phone: this.form.phone,
            message: this.form.message,
          })
          .then(() => {
            this.isLoading = false;
            this.success = true;
          })
          .catch((err) => {
            console.log(err);
            this.isLoading = false;
            if (err.response && err.response.data && err.response.data.errors) {
              this.formErrors = err.response.data.errors;
            }
          });
    },
  },
};
</script>

<style>
.otp-input {
  width: 40px;
  height: 40px;
  margin: 0 5px;
  font-size: 20px;
  text-align: center;
  border: 2px solid #ccc;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.2s ease-in-out;
}

.otp-input:focus {
  border-color: #d60c56;
  box-shadow: 0 0 5px rgba(214, 12, 86, 0.1);
}
</style>
